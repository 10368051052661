import {AccessAgreement} from "../types/AccessAgreement";

export class TrackingInfo {
    public static readonly NOTSET: string = 'NOTSET';
    public static readonly NO: string = 'no';
    public static readonly YES: string = 'yes';

    client: string;
    anonId: string;
    userId: string;
    userAuthenticated: string;
    userAuthorized: string;
    grpId: string;
    grpAuthenticated: string;
    grpAuthorized: string;
    authorizationMethod: string;
    abGroup: number = 0;
    sectionId: number;
    sectionPathId: Array<number>;
    sectionName: string;
    contentType: string;
    pageName: string;
    pageType: string;
    pageRestricted: string;
    pageRestrictedType: string;
    site: string;
    subSite: string = 'live';
    editorialCategory: string;
    contentContext: string = 'default';
    sectionContext: string = 'default';
    contentId: number;
    accessAgreements: AccessAgreement[];

    constructor() {
        this.client = TrackingInfo.getClient();
        this.anonId = TrackingInfo.getAnonId();
        this.userId = TrackingInfo.getUserId();
        this.userAuthenticated = TrackingInfo.getUserAuthenticated();
        this.userAuthorized = TrackingInfo.getUserAuthorized();
        this.grpId = TrackingInfo.getGroupId();
        this.grpAuthenticated = TrackingInfo.getGroupAuthenticated();
        this.grpAuthorized = TrackingInfo.getGroupAuthorized();
        this.authorizationMethod = TrackingInfo.getAuthorizationMethod();
        this.accessAgreements = TrackingInfo.getAccessAgreements();

        this.sectionId = TrackingInfo.getSectionId();
        this.sectionPathId = TrackingInfo.getSectionPathId();
        this.sectionName = TrackingInfo.getSectionName();
        this.contentType = TrackingInfo.getContentType();
        this.contentId = TrackingInfo.getContentId();
        this.pageName = TrackingInfo.getPageName();
        this.pageType = TrackingInfo.getPageType();
        this.pageRestricted = TrackingInfo.getPageRestricted();
        this.pageRestrictedType = TrackingInfo.getPageRestrictedType();
        this.site = TrackingInfo.getSite();
        this.editorialCategory = TrackingInfo.getEditorialCategory();
    }

    static getClient(): string {
        const DEFAULT: string = TrackingInfo.NOTSET;
        return (!window.commonJpData || !window.commonJpData.client) ? DEFAULT : window.commonJpData.client;
    }

    static getAnonId(): string {
        const DEFAULT: string = TrackingInfo.NOTSET;
        return (!window.commonJpData || !window.commonJpData.anonId) ? DEFAULT : window.commonJpData.anonId;
    }

    static getUserId(): string {
        const DEFAULT: string = 'anon';
        return (!window.commonJpData || !window.commonJpData.userId) ? DEFAULT : window.commonJpData.userId;
    }

    static getUserAuthenticated(): string {
        const DEFAULT: string = TrackingInfo.NO;
        return (!window.commonJpData || !window.commonJpData.userAuthenticated) ? DEFAULT : TrackingInfo.getStringFromBool(window.commonJpData.userAuthenticated);
    }

    static getUserAuthorized(): string {
        const DEFAULT: string = TrackingInfo.NO;
        return (!window.commonJpData || !window.commonJpData.userAuthorized) ? DEFAULT : TrackingInfo.getStringFromBool(window.commonJpData.userAuthorized);
    }

    static getGroupId(): string {
        const DEFAULT: string = TrackingInfo.NOTSET;
        return (!window.commonJpData || !window.commonJpData.groupId) ? DEFAULT : window.commonJpData.groupId;
    }

    static getGroupAuthenticated(): string {
        const DEFAULT: string = TrackingInfo.NO;
        return (!window.commonJpData || !window.commonJpData.groupAuthenticated) ? DEFAULT : TrackingInfo.getStringFromBool(window.commonJpData.groupAuthenticated);
    }

    static getGroupAuthorized(): string {
        const DEFAULT: string = TrackingInfo.NO;
        return (!window.commonJpData || !window.commonJpData.groupAuthorized) ? DEFAULT : TrackingInfo.getStringFromBool(window.commonJpData.groupAuthorized);
    }

    static getAuthorizationMethod(): string {
        const DEFAULT: string = TrackingInfo.NOTSET;
        return (!window.commonJpData || !window.commonJpData.authorizationMethod) ? DEFAULT : window.commonJpData.authorizationMethod;
    }

    static getAccessAgreements(): AccessAgreement[] {
        const DEFAULT: AccessAgreement[] = [];
        return (!window.commonJpData || !window.commonJpData.accessAgreements || !Array.isArray(window.commonJpData.accessAgreements)) ? DEFAULT : window.commonJpData.accessAgreements
    }

    static getSectionId(): number {
        const DEFAULT: number = 0;
        return (!window.commonJpData || !window.commonJpData.sectionId) ? DEFAULT : window.commonJpData.sectionId;
    }

    static getSectionPathId(): number[] {
        const DEFAULT: number[] = [];
        return (!window.commonJpData || !window.commonJpData.sectionPathId || !Array.isArray(window.commonJpData.sectionPathId)) ? DEFAULT : window.commonJpData.sectionPathId;
    }

    static getSectionName(): string {
        const DEFAULT: string = TrackingInfo.NOTSET;
        return (!window.commonJpData || !window.commonJpData.sectionName) ? DEFAULT : window.commonJpData.sectionName;
    }

    static getContentType(): string {
        const DEFAULT: string = TrackingInfo.NOTSET;
        return (!window.commonJpData || !window.commonJpData.contentType) ? DEFAULT : window.commonJpData.contentType;
    }

    static getContentId(): number {
      const DEFAULT: number = 0;
        return (!window.commonJpData || !window.commonJpData.contentId) ? DEFAULT : window.commonJpData.contentId;
    }

    static getPageName(): string {
        const DEFAULT: string = TrackingInfo.NOTSET;
        return (!window.commonJpData || !window.commonJpData.pageName) ? DEFAULT : window.commonJpData.pageName;
    }

    static getPageType(): string {
        const DEFAULT: string = TrackingInfo.NOTSET;
        return (!window.commonJpData || !window.commonJpData.pageType) ? DEFAULT : window.commonJpData.pageType;
    }

    static getPageRestricted(): string {
        const DEFAULT: string = TrackingInfo.NO;
        return (!window.commonJpData || !window.commonJpData.pageRestricted) ? DEFAULT : TrackingInfo.getStringFromBool(window.commonJpData.pageRestricted);
    }

    static getPageRestrictedType(): string {
        const DEFAULT: string = 'free';
        return (!window.commonJpData || !window.commonJpData.pageRestrictedType) ? DEFAULT : window.commonJpData.pageRestrictedType;
    }

    static getSite(): string {
        const DEFAULT: string = TrackingInfo.NOTSET;
        return (!window.commonJpData || !window.commonJpData.site) ? DEFAULT : window.commonJpData.site;
    }

    static getEditorialCategory(): string {
        const DEFAULT: string = TrackingInfo.NOTSET;
        return (!window.commonJpData || !window.commonJpData.editorialCategory) ? DEFAULT : window.commonJpData.editorialCategory;
    }

    public static getStringFromBool(bool: Boolean): string {
        return bool ? this.YES : this.NO;
    }

    public toString = () => JSON.stringify(this, null, '  ');
}
