import {IAdditionalData} from "./Trackers/SnowPlow";

export default class TrackingHelper {
    static getFirstTrackedItem(key: string, trackedItems: Map<string, string[]>): string | null {
        const arr = trackedItems.get(key);
        if(arr){
            return arr[0];
        }
        return null;
    }

    // This is done since some of the elements we want to track are not an anchor tag with a href.
    static getHref(clickedElement: HTMLElement | null) {
        let target = clickedElement;

        // Go up the DOM hierarchy until we find an anchor tag or button.
        while (target !== document.body && target) {
            if (target instanceof HTMLAnchorElement) {
                return target.href? target.href : "";
            }
            if (target instanceof HTMLButtonElement) {
                // This is done since some of the elements we want to track (e.g. log in button)
                // is a button instead of an anchor tag with a href.
                return "";
            }
            target = target.parentElement;
        }

        return null;
    }

    static getTrackedItemsAsString(key: string, trackedItems: Map<string, string[]>): string | null {
        const arr = trackedItems.get(key);
        if(arr && arr.length > 0){
            return arr.join('_');
        }
        return null;
    }

    static getAdditionalTrackingData(trackedItems: Map<string, string[]>): IAdditionalData[] {
        const additionalDataPrefix = 'additionaldata';

        const additionalData: IAdditionalData[] = [];

        trackedItems.forEach((value, key ) => {
            if (key.startsWith(additionalDataPrefix) && value.length > 0) {
                let newKey = key.replace(additionalDataPrefix, "");
                additionalData.push({key: newKey, value: value[0]});
            }
        });

        return additionalData;
    }

    static getTrackingLabels(trackedItems: Map<string, string[]>): string[] {
        const trackingLabelsPrefix = 'trackinglabels';

        const trackingLabels: string[] = [];

        trackedItems.forEach((labels, key ) => {
            if (key.startsWith(trackingLabelsPrefix) && labels.length > 0) {
                labels.forEach(value => trackingLabels.push(value))
            }
        });


        return trackingLabels;
    }
}
